

export const TransactionFunctions = {

    /**
     * Given the transaction information, calculate totals
     * @param {type} unitCost base cost of the item
     * @param {type} quantity number of units
     * @param {type} discount percent discount
     * @param {type} tax percent tax
     * @returns object containing subtotal, tax, and total amounts
     */
    calculateTransactionTotals: (unitCost, quantity, discount, tax) => {

        const sub = quantity * (unitCost - unitCost * (discount/100.0));

        const taxVal = sub * (tax/100.0);

        return {subtotal: sub, tax: taxVal, total: sub + taxVal};
    },

    /**
     * Given a quantity and a product, validates the quantity from product restrictions and
     * returns the limited quantity
     * @param {Integer} quantity desired quantity
     * @param {Product} product in question
     * @param {Boolean} allowZero to permit zero quantity
     * @returns updated quantity
     */
    validateQuantity: (quantity, product, allowZero = false) => {
        
        if (quantity < 0)
            quantity = 0;
        
        if (quantity > 100000)
            quantity = 100000;
        
        if (quantity === 0 && allowZero)
            return quantity;
                
         //Limit to product min/max quantities
        if (product.maxPerMembership && quantity > product.maxPerMembership)  //max set, limit to max
            quantity = product.maxPerMembership;

        if (product.unlimitedRedeem())  //limit to 1 for unlimited redeemables
            quantity = 1;

        if (product.redeemable() && product.inventory != null && quantity >= product.inventory)
            quantity = product.inventory;

        if ((quantity % product.minQuantity) !== 0) 
            quantity = Math.ceil(quantity / product.minQuantity) * product.minQuantity;

        if (quantity < product.minQuantity)
            quantity = product.minQuantity;
       
        
        return quantity;
    },

    /**
     * Given a shopping cart, calculate the total cost of all items and indicate number of products requiring membership (product requires it with > 0 quantity)
     * @param cart array of objects, each object has the properties: {product: Product, quantity: Integer}
     * @returns an object containing the fields {subtotal, tax, total, items requiresMembership}
     */
    totalShoppingCart: (cart) => {

        let subtotal = 0;
        let tax = 0;
        let total = 0;
        let requiresMembership = 0;
        let items = 0;

        for (let item of cart) {
            const p = item.product;

            const totals = TransactionFunctions.calculateTransactionTotals(p.cost, item.quantity, p.discount, p.tax);           

            subtotal += totals.subtotal;            
            tax += totals.tax;      
            total += totals.total;

            if (item.quantity > 0 && p.requiresMembership())
                requiresMembership++;

            items += item.quantity;

        }
        return {subtotal: subtotal, tax: tax, total: total, items: items, requiresMembership: requiresMembership};
    }


    
};

