import React from 'react';
import { withStyles } from '@material-ui/core/styles';

import { TextField, IconButton, Tooltip, Typography, Divider, Grid } from '@material-ui/core'
import DeleteIcon from '@material-ui/icons/Delete';
import ClearIcon from '@material-ui/icons/Clear';
import PlusOneIcon from '@material-ui/icons/PlusOne';
import InfoIcon from '@material-ui/icons/Info';
import AddShoppingCartIcon from '@material-ui/icons/AddShoppingCart';
import WarningIcon from '@material-ui/icons/Warning';

import { ImageFunctions } from '../utils/Image';
import { ThemeColors } from '../Theme'
import { Currency } from 'react-frontend-utils'

/**
 * A react function that generates an entry in a product list for the product. Based on the supplied quantity, the
 * total value is also calculated. A divider is added at the top for all elements except the first.  A header is
 * also.
 * 
 * The caller must pass the following props:
 * 
 * item: the item to render, which has the following fields: 
 *      {product: a Product to render,
 *       quantity: the number to set in the quantity field,
 *       quantityStep: the step increment for the quantity increment/decrement}
 *      
 * mode: one of the ProductListModes, determines how to render depending on the use case
 *      
 * index: the index of the product from an array
 * isMobile: for smaller size, removes buttons
 * isVerySmall: for really small sizes, shrinks content even more
 * quantityChanged: a callback function when the user changes the quantity, passed the product index an the new value
 * action: a callback function when the IconButton in the last column is pressed, passed the product index (can be undefined/null to remove the action button)
 * disableReason: String, if not null, disables the item and presents this as a Tooltip
 * fixedPoint: number of digits for fixed point values
 * accountHasUnlimitedRedeemable: true if an account already has the redeemable
 * currencySymbol: the currency symbol for prices
 * hideRedeemables: only for the Search mode, if true, does not display the product if it is a Redeemable
 */


export const ProductListModes = {
    MARKET: 0,      //Market Tab, Attendant
    REDEEM: 1,      //Redeem Tab, Attendant
    PUBLIC: 2,      //Public Portal, Patron
    SEARCH: 3       //Search List, Attendant 
};


export const ProductInfoTooltip = withStyles((theme) => ({
    tooltip: {
        backgroundColor: ThemeColors.tooltipLightGray,
        color: 'blue',
        maxWidth: 500,
        fontSize: theme.typography.pxToRem(15),
        border: '2px solid gray'
    }
}))(Tooltip);

export default function ProductListEntry(props) {
    
    
    const index = props.index;
    const product = props.item.product;
    const quantity = props.item.quantity;
    const step = props.item.quantityStep ? props.item.quantityStep : 1;
    const disabled = props.disableReason ? true : false;
    let hasQuantity = true;  //default, most modes have quantity
                
    let col1Title;
    let col2Title;
    let col3Title;
    let col4Title;
    let col1Text;   
    let col2Text;
    let col3Text;
    let col4Text;
    let colSizes = [];  //4 element array
    let tooltip;
    let col5Icon = null;
    let col5Tooltip = null;
    let warnText = null;        
    let showID = true;
    let costText = null;
            
    switch (props.mode) {
       
        case ProductListModes.MARKET:

            col1Title = "UNIT COST";
            col2Title = props.isMobile ? "DISC" : "DISCOUNT";
            col3Title = "TAX";
            col4Title = props.isVerySmall ? "LINE TTL" : "LINE TOTAL";
            
            col1Text = Currency.round(product.cost, props.fixedPoint);  //holds cost for purchase
            col2Text = product.discount.toFixed(1) + "%";  //holds % discount for purchase
            col3Text = product.tax.toFixed(2) + "%";
            col4Text = props.currencySymbol + Currency.round(product.totalPrice(quantity), props.fixedPoint);

            colSizes = [3, 2, 2, 3];
            tooltip = disabled ? props.disableReason : product.describe();   //false = for attendant, false = for purchase

            col5Icon = <DeleteIcon/>;
            col5Tooltip = "Remove Product";
            
            if (props.accountHasUnlimitedRedeemable)
                warnText = "Ticket already holds this unlimited redeemable. Make sure you want to purchase it again.";

            break;
        
        case ProductListModes.REDEEM:
                
            col1Title = props.isMobile ? "AVAIL" : "AVAILABLE";
            col2Title = "REMAIN" 
            col3Title = null;
            col4Title = null;
        
            col1Text = product.cost;  //"cost" actually holds remaining quantity for redeem
            col2Text = product.discount;  //"discount" holds time remaining for redeem
            col3Text = null;
            col4Text = null;
            
            colSizes = [3, 3, 1, 3];
            tooltip = disabled ? props.disableReason : product.describe(false, true); //false = for attendant, true = for redeem
            col5Icon = <ClearIcon/>;
            col5Tooltip = "Zero Quantity";
            
            break;
                           
        case ProductListModes.PUBLIC:
        
            col1Title = props.isMobile ? "AVAIL" : "AVAILABLE";
            col2Title = null;
            col3Title = props.isVerySmall ? "LINE TTL" : "LINE TOTAL";;
            col4Title = null;
            
            const unitPrice = product.totalPrice(1);
            costText = props.currencySymbol + Currency.round(unitPrice, props.fixedPoint);
            
            //Available is the leser of maxPerMembership (if set) or i
            let max = null;
            if (product.maxPerMembership && product.inventory != null)
                max = product.maxPerMembership < product.inventory ?  product.maxPerMembership : product.inventory;
            else if (product.maxPerMembership)           
                max = product.maxPerMembership;
            else
                max = product.inventory;
            
            col1Text = max == null ? "Unlimited" : max;  
            col2Text = null;
            const totalPrice = product.totalPrice(quantity);
            col3Text = totalPrice ? Currency.round(totalPrice, props.fixedPoint) : "";
            col4Text = null;

            colSizes = [3, 1, 3, 3];
            tooltip = disabled ? props.disableReason : product.describe(true);  //true = for patron!
            col5Icon = <ClearIcon/>;
            col5Tooltip = "Zero Quantity";
            showID = false;
            break;
            
         case ProductListModes.SEARCH:
             
            if (props.hideRedeemables && product.redeemable())  //do not show this redeemable
                return null;
             
            hasQuantity = false; 
            
            col1Title = "UNIT COST";
            col2Title = props.isMobile ? "DISC" : "DISCOUNT";
            col3Title = "TAX";
            col4Title = null;
            
            col1Text = Currency.round(product.cost, props.fixedPoint);  //holds cost for purchase
            col2Text = product.discount.toFixed(1) + "%";  //holds % discount for purchase
            col3Text = product.tax.toFixed(2) + "%";
            col4Text = null;

            colSizes = [3, 2, 2, 3];
            tooltip = product.describe(false); //false = for attendant
           
            col5Icon = <AddShoppingCartIcon/>;
            col5Tooltip = "Add to Order";
            break;
            
        default:
            return <div>Unhandled Product List Mode</div>;
    }
    
     if (props.isMobile && col1Text === "Unlimited")  //when col1 is a quantity
        col1Text = "∞";
    
    
    const fontSize = props.isVerySmall ? 12 : (props.isMobile ? 14 : 16);
    const quantityLabel = hasQuantity ? (props.isVerySmall ? "QUAN" : "QUANTITY") : null;
  
    
    const styles = {
       
        divider: {
            marginLeft: 10,
            marginRight: 10
        },
        text: {
            color: disabled ? 'gray' : 'black',
            fontSize: fontSize,
            marginLeft: 5
        },
        caption: {
            fontSize: fontSize-2
        }
    };
    
    
    
    return (
            
        <div key={index}>

             {index === 0 ?  /*Header*/
                <div>
                    <div style={{marginLeft: 10, marginRight: 10}}>
                        <Grid container direction="row" justify='center' alignItems='center' spacing={props.isVerySmall ? 1 : 2}>

                            <Grid item xs={4}/>

                            <Grid item xs={2}>
                                <Typography variant="body2" align='center' style={styles.caption}>{quantityLabel}</Typography>   
                            </Grid>  

                            <Grid item xs={6}>

                                <Grid container direction="row" justify="center" alignItems="center" spacing={props.isVerySmall ? 1 : 2}>

                                    <Grid item xs={colSizes[0]}>
                                        <Typography variant="body2" align='right' style={styles.caption}>{col1Title}</Typography>   
                                    </Grid>

                                    <Grid item xs={colSizes[1]}>
                                        <Typography variant="body2" align='right' style={styles.caption}>{col2Title}</Typography>   
                                    </Grid> 

                                    <Grid item xs={colSizes[2]}>
                                        <Typography variant="body2" align='right' style={styles.caption}>{col3Title}</Typography>   
                                    </Grid>

                                    <Grid item xs={colSizes[3]}>
                                        <Typography variant="body2" align='right' style={styles.caption}>{col4Title}</Typography>   
                                    </Grid>
                                    
                                    {props.isVerySmall ? null : 
                                    <Grid item xs={2}/>}
                                    
                                </Grid>
                            </Grid>
                        </Grid>
                    </div>
                    <Divider/>            
                </div> : <Divider style={styles.divider}/>
             }
            
             <div style={{margin: 10}}>
             <Grid container direction="row" justify='center' alignItems='center' spacing={props.isVerySmall ? 1 : 2}>

  
                <Grid item xs={1}>    
                    {ImageFunctions.productImageURL(product, props.isVerySmall ? 6.0 : 4.0, disabled ? 0.4 : 1.0)}
                </Grid>

                <Grid item xs={3}>
                    <div>
                        <span style={{display: 'flex'}}>
                            
                            <div style={{flexGrow: 1}}>
                                {showID ? <Typography variant="body2" style={styles.text}>{product.id}</Typography> : null}
                                <Typography variant="body2" style={styles.text}>{product.name}</Typography> 
                                {costText ? <Typography variant="body2" style={styles.text}>{costText}</Typography> : null}
                            </div>
                            <div>
                                {warnText ? 
                                    <Tooltip title={warnText} enterTouchDelay={0}>
                                        <IconButton edge="end" style={{marginTop: 0}}>
                                            <WarningIcon style={{color: 'yellow', backgroundColor: 'black', fontSize: fontSize+4}} />
                                        </IconButton>
                                    </Tooltip> : null
                                }
                                <ProductInfoTooltip title={tooltip} enterTouchDelay={0}>
                                    <IconButton edge="end" style={{marginTop: 0, marginRight: 3}}>
                                        <InfoIcon style={{color: 'blue', fontSize: fontSize+6}} />
                                    </IconButton>
                                </ProductInfoTooltip>                             
                            </div>
                        </span>  


                    </div>
                </Grid>

                <Grid item xs={2}>
                          
                    {hasQuantity ? <TextField style={props.isVerySmall ? {maxWidth: 40} : {}} variant={props.isVerySmall ? "standard" : "outlined"} size="small"
                                    value={quantity} 
                                    fullWidth={true}
                                    disabled={disabled}
                                    type="number"
                                    onChange={(event) => props.quantityChanged(index, event.target.value)} 
                                    inputProps={{step: step}}
                                    InputProps={{style: {fontSize: fontSize}}}
                                    InputLabelProps={{ shrink: true }}/>
                                   : null
                    }
           
                </Grid>
             
                <Grid item xs={6}>

                    <Grid container direction="row" justify="center" alignItems="center" spacing={props.isVerySmall ? 1 : 2}>

                        <Grid item xs={colSizes[0]}>
                            <Typography variant="body2" align='right' style={styles.text}>{col1Text}</Typography>   
                        </Grid>
                            
                        <Grid item xs={colSizes[1]}>
                            <Typography variant="body2" align='right' style={styles.text}>{col2Text}</Typography>   
                        </Grid> 

                        <Grid item xs={colSizes[2]}>
                            <Typography variant="body2" align='right' style={styles.text}>{col3Text}</Typography>                          
                        </Grid>

                        <Grid item xs={3}>
                            {(props.mode === ProductListModes.REDEEM || props.mode === ProductListModes.PUBLIC) && !disabled ? 
                                <Tooltip title={"Add one to Redeem Quantity"}>
                                    <IconButton edge={'end'} onClick={() => props.quantityChanged(index, quantity+1)} style={{marginTop: -2}}>
                                        <PlusOneIcon/>
                                     </IconButton>
                                </Tooltip> 
                                :<Typography variant="body2" align='right' style={styles.text}>{col4Text}</Typography> 
                                }
                        </Grid>
                        
                        {!col5Icon ? null :
                        <Grid item xs={2}>
                            {!disabled ? 
                            <Tooltip title={col5Tooltip}>
                                <IconButton edge='start' onClick={() => props.action(index)} style={{marginTop: -4, marginLeft: props.isMobile ? null : 6}}>
                                    {col5Icon}
                                 </IconButton>
                            </Tooltip> : null}      
                        </Grid>}
                        
                        
                    </Grid>
                </Grid>

            </Grid>
            </div>
        </div>
                
      
    );
        
}