import React from 'react';
import InfoIcon from '@material-ui/icons/Info';

import { PP } from './PP'
import { DateUtils, Currency } from 'react-frontend-utils'


export const ProductAttributes = [{name: "ADMITTANCE", tooltip: "Redeeming this item admits a person into the facility, increasing occupancy statistics"}, 
                                  {name: "REDEEMABLE", tooltip: "This product can be purchased and redeemed later. If not selected, the product must be instantly redeemed at the time of purchase."},
                                  {name: "REQUIRES MEMBERSHIP", tooltip: "Purchasing this product requires the customer to hold a valid Membership ID"},
                                  {name: "UNLIMITED REDEEM", tooltip: "This product can be redeemed an unlimited number of times"},
                                  {name: "ADDS GUEST PASS", tooltip: "Purchasing this product will add a guest pass to the Membership"},
                                  {name: "NOTIFIES BY EMAIL", tooltip: "Purchasing this product will send a notification email to the Marketplace email address"}];

export const DaysOfWeek = [{name: "Sun"}, {name: "Mon"}, {name: "Tue"}, {name: "Wed"}, {name: "Thu"}, {name: "Fri"}, {name: "Sat"}];

const DaysOfWeekExpanded = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];


/**
 * This class represents a Product in the Marketplace, received from a JsonProduct
 */
export class Product {
    
    static MAX_PRODUCT_ID_LEN = 34;

    
    id;                                         //may not be null
    name;                                       //string
    info = "";                                  //string
    category = "";                              //string
    imageBase64;                                //base64 encoded member image, or null
    attributes;                                 //integer, bitmask of Attributes
    maxPerMembership = null;                    //integer, null to ignore
    minQuantity = 1;                            //integer
    inventory = null;                           //integer, null for unlimited
    reorder = null;                             //integer, null for never
        
    startDate = null;                           //Date, may be null for any
    endDate = null;                             //Date, may be null for any
    startTime = null;                           //jsonTimeString, null any
    endTime = null;                             //jsonTimeString, null any
    dayOfWeek = 0x7F;                           //integer, bitmask
    afterPurchase = null;                       //integer, minutes
    afterFirstRedeem = null;                       //integer, minutes
    
    enabled = false;                            //boolean
    cost;                                       //float
    tax;                                        //float
    discount;                                   //float
    
    lastTransaction;    
  
 
    constructor(productJson) {
        
        if (productJson) {
            this.id = productJson.id;
            this.name = productJson.name;
            this.info = productJson.info;
            this.category = productJson.category;
            this.imageBase64 = productJson.imageBase64;
            this.attributes = productJson.attributes;
            
            this.maxPerMembership = productJson.maxPerMembership;
            this.minQuantity = productJson.minQuantity;
            
            this.inventory = productJson.inventory;
            this.reorder = productJson.reorder;
        
            if (productJson.startDate)
                this.startDate = DateUtils.parseJsonDate(productJson.startDate); 
            
            if (productJson.endDate)
                this.endDate = DateUtils.parseJsonDate(productJson.endDate); 
      
            this.startTime = productJson.startTime;
            this.endTime = productJson.endTime;
            this.dayOfWeek = productJson.dayOfWeek;
            this.afterPurchase = productJson.afterPurchase;
            this.afterFirstRedeem = productJson.afterFirstRedeem;

            this.enabled = productJson.enabled;
            this.cost = productJson.cost;
            this.tax = productJson.tax;
            this.discount = productJson.discount;
                 
            this.lastTransaction = productJson.lastTransaction;
   
        }       
    }
    
    
    //Create a new Product JSON from id and name, with defaults for properties that aren't null by default
    static createNewJson(id, name) {
        
        const newProduct = {id: id,
                            name: name,
                            minQuantity: 1,
                            enabled: true,
                            dayOfWeek: 0x7F
                            };
        
        return newProduct;
    }
    
    static attributeBitPosition(attrName) {
   
        for (let i=0; i<ProductAttributes.length; i++) {
            if (ProductAttributes[i].name === attrName)
                return i;
        }
    }
   
     
    
    admits = () => {
        const shift = Product.attributeBitPosition("ADMITTANCE");
        return ((this.attributes >> shift) & 0x1) === 1;
    }
    
    requiresMembership = () => {
        const shift = Product.attributeBitPosition("REQUIRES MEMBERSHIP");
        return ((this.attributes >> shift) & 0x1) === 1;
    }
    
    redeemable = () => {
        const shift = Product.attributeBitPosition("REDEEMABLE");
        return ((this.attributes >> shift) & 0x1) === 1;
    }
    
    unlimitedRedeem = () => {
        const shift = Product.attributeBitPosition("UNLIMITED REDEEM");
        return ((this.attributes >> shift) & 0x1) === 1;
    }
    
    addsGuestPass = () => {
        const shift = Product.attributeBitPosition("ADDS GUEST PASS");
        return ((this.attributes >> shift) & 0x1) === 1;
    }
    
    isAutoRedeemable = () => {
        return this.addsGuestPass(); //these types are auto redeemable
    }
    
    validDays = () => {
    
        let days = [];
        for (let i=0; i<DaysOfWeekExpanded.length; i++) {
            if ((this.dayOfWeek >> i) & 0x1) {
                days.push(DaysOfWeekExpanded[i]);
            }
        }
        return days;
    }
    
    
    totalPrice = (quan = 1) => {
        const baseCost = (this.cost - (this.cost * (this.discount/100.0)));
        const totalCost = baseCost + baseCost * (this.tax / 100.0);
        return quan * totalCost;
    }
    
    
    
    //Describe the product for the attendant or patron.  If "forCustomer" is true, then the description is intended for the patron.
    describe = (forCustomer = false, forRedeem = false) => {
        
        let introString = "";
        if (!forRedeem)
            introString = this.enabled ? ("Available for purchase" + (this.requiresMembership() ? " by Members only. " : ". ")) : "Purchasing is disabled. ";
        
        if (!forRedeem) {  //for redeem, we know its redeemable at a future date
            if (this.redeemable()) {
                const unlimited = this.unlimitedRedeem() ? " for an unlimited number of times." : ".";
                introString += "This product is redeemable at a future date" + unlimited;
            }
            else
                introString += "This product is redeemed immediately after purchase. ";
        }
        
        if (this.admits() && !forCustomer) 
            introString += " Redeeming this product allows entry to the facility and increases occupancy count.";
        
        let costString = "";
        if (!forCustomer && !forRedeem) {
            let isoCode = ""; 
            if (!Currency.symbolForISO(PP.currency))
                isoCode = PP.currency; //only print iso code if we have no symbol
        
            costString = "With discounts and tax, the product will cost the customer " + 
                          Currency.symbolForISO(PP.currency) + 
                          Currency.round(this.totalPrice()) + isoCode + "."; 
        }
        
        let maxminString = "";
        if (!forRedeem) {
            if (this.maxPerMembership > 0) {
                if (this.requiresMembership())
                    maxminString = "There is a limit of " + this.maxPerMembership + " purchases per Membership.";
                else
                    maxminString = "There is a limit of " + this.maxPerMembership + " per purchase transaction.";
            }
            else
                maxminString = "There is no limit to the amount " + (forCustomer ? "you" : "the customer") + " may purchase, inventory permitting.";

            if (this.minQuantity > 1) {
                maxminString = forCustomer ? " You" : " The customer";
                maxminString += " must buy in lots of " + this.minQuantity + ", i.e. " + this.minQuantity + ", " + (this.minQuantity * 2) + ", " + (this.minQuantity * 3) + "...";
            }
        }
        
        let dateLimitations = "";
        if (this.startDate) {
            dateLimitations = "The product may only be redeemed starting on " + PP.dateFormat(this.startDate);
            if (this.endDate)
                dateLimitations += " until the end of the day on " + PP.dateFormat(this.endDate) + ".";
            else
                dateLimitations += ".";
        }
        else if (this.endDate)
            dateLimitations = "The product may not be redeemed after " + PP.dateFormat(this.endDate) + ".";

        let timeLimitations = "";
        if (this.startTime && this.endTime) {
            const timeDiff = DateUtils.diffTimeStrings(this.startTime, this.endTime);  //wrap midnight - negative
            const extension = timeDiff < 0 ? " the following day." : ".";
            timeLimitations = "The product may only be redeemed between " + DateUtils.toFriendlyTimeString(this.startTime) + " and " + DateUtils.toFriendlyTimeString(this.endTime) + extension;
        
        } else if (this.startTime)
            timeLimitations = "The product may only be redeemed after " + DateUtils.toFriendlyTimeString(this.startTime) + ".";
        else if (this.endTime)
            timeLimitations = "The product may not be redeemed after " + DateUtils.toFriendlyTimeString(this.endTime) + ".";
        
        
        let dayLimitations = "";
        const validDays = this.validDays();
        if (validDays.length < 7) {
            
            if (validDays.length === 0)
                dayLimitations = "The product is not redeemable on any day of the week.";
            else if (validDays.length === 2 && validDays[0] === "Sunday" && validDays[1] === "Saturday")
                dayLimitations = "The product may only be redeemed on weekends.";
            else if (validDays.length === 5 && !validDays.includes("Sunday") && !validDays.includes("Saturday"))
                dayLimitations = "The product may only be redeemed on weekdays.";
            else {
                dayLimitations = "The product may only be redeemed on ";
                validDays.forEach(day => dayLimitations += day + "s and ");
                
                dayLimitations = dayLimitations.slice(0, -5) + ".";  //get rid of last and
            }
        }
        
        let afterLimitations = "";
        if (this.redeemable() && this.unlimitedRedeem()) {
            if (this.afterPurchase > 0) {
                afterLimitations = "The product must be redeemed within " + DateUtils.relativeTimeFriendlyFormat(this.afterPurchase) + " after it is purchased";
                if (this.afterFirstRedeem > 0)
                    afterLimitations += " and the ";
                else
                    afterLimitations += ".";
            }
            if (this.afterFirstRedeem > 0) {
                if (!this.afterPurchase)
                    afterLimitations += "The product must be redeemed ";
                afterLimitations += "within " + DateUtils.relativeTimeFriendlyFormat(this.afterFirstRedeem) + " after it is first redeemed.";        
            }
        }
     
        if (!dateLimitations && !timeLimitations && !dayLimitations && !afterLimitations)
            dateLimitations = "There are no restrictions on when the product can be redeemed.";
     
        return (<div>
                    <InfoIcon style={{float: 'left', color: 'blue', paddingRight: 5}}/>
                    <span style={{display: 'flex', paddingTop: 1, paddingBottom: 10}}>
                        <div style={{fontWeight: 'bold', flexGrow: 1}}>{this.name}</div>
                        <div style={{fontWeight: 'bold', textAlign: 'right'}}>{this.category}</div>
                    </span>
                    <div style={{paddingTop: 8}}>{this.info}</div> 
                    {introString ? <div style={{paddingTop: 8}}>{introString}</div> : null}
                    {costString ? <div style={{paddingTop: 8}}>{costString}</div> : null}
                    {maxminString ? <div style={{paddingTop: 8}}>{maxminString}</div> : null}
                    {dateLimitations ? <div style={{paddingTop: 8}}>{dateLimitations}</div> : null}
                    {timeLimitations ? <div style={{paddingTop: 8}}>{timeLimitations}</div> : null}
                    {dayLimitations ? <div style={{paddingTop: 8}}>{dayLimitations}</div> : null}
                    {afterLimitations ? <div style={{paddingTop: 8}}>{afterLimitations}</div> : null}
                </div>);  
    }
    
}