import React, { Fragment } from 'react';
import { withCookies } from 'react-cookie';
import stripebadge from '../assets/stripebadge.png';

import { Typography, TextField, Paper, Button, Tooltip } from '@material-ui/core'

import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';

import { Order, PatronOrder } from '../models/Order'
import { AccountRequest } from '../models/Account'
import { RestComponent } from 'react-frontend-utils'

import { StripeManager } from 'react-frontend-utils'

/**
 * 
 * Checkout page for a single database's Patron Portal.  
 * 
 * The caller must pass the props:
 * 
 * returnToStore:    a function to be called to go back to the Store page
 * purchaseComplete: a function to be called when purchasing is complete (passing the accountID and true if there was a charge, false if free)
 * database:         database (PublicJsonGroup) retrieved from GET /patron/databases/{databaseName}
 * account:          a PublicAccount object if the portal was visited using a referrer link, null otherwise (for guest)
 * checkout:         an object containing:
 *                      cart:            the products to buy
 *                      funds:           the funds to add
 *                      membershipInfo:  the membership info, if required
 *                      isFree:          true if the total for checkout is zero
 *
 *
 * If no account is set, provide text entry for name and email.
 * 
 * The StripeManager component takes care of the purchase. It calls the requestPurchase function to submit the order to the server 
 * and receive the client secret to use to complete the payment
 *
 */

export class Checkout extends RestComponent {

    styles = {
        paperLabel: {
            color: 'blue',
            fontSize: '12pt',
            flexGrow: 1
        }
    }
        
    constructor(props) {
        super(props);
        this.state.name = "";       //Name for the account
        this.state.email = "";      //Email for the account
        this.state.cardChargeFailure = false;
        this.state.checkedOutAccount = null;  //The account that was charged / took free items
    }

    _returnToStore = () => {
        this.setState({cardChargeFailure: false});
        this.props.returnToStore();
    }


    
    _requestPurchase = (completePurchase, cancelPurchase) => {
        
        const accountID = this.props.account ? this.props.account.id : null;
                
        //Create a Order from the cart.  The account may be null if guest.  No comment provided.
        const order = Order.createFromCheckout(true, this.props.checkout.cart, accountID, this.props.checkout.membershipInfo, null);
        
        let guestAccount = null;
        if (this.props.account == null) {
            console.log("Creating order as guest (" + this.state.name + ")");
            guestAccount = new AccountRequest(this.state.name, this.state.email);
        }
        else {
            console.log("Creating order for existing patron");
        }
        
        //Create the Patron order with the guest account (null if existing account), the order, and requested funds
        const patronOrder = new PatronOrder(guestAccount, order, this.props.checkout.funds, this.props.database.isoCurrency);
                
        this.secureJSONFetch("/patron/databases/" + this.props.database.name + "/order",
                             {method: "POST", body: JSON.stringify(patronOrder)}, 
                             (response) => {this._orderSucceeded(response, completePurchase, cancelPurchase);}, 
                             (response) => {this._orderFailed(response, cancelPurchase);}); 
    }


    _orderSucceeded = (response, completePurchase, cancelPurchase) => {
        if (response) {  //response is JsonOrderSuccess (two elements)          
            const clientSecret = response.clientSecret;
            this.setState({checkedOutAccount: response.accountID});
            completePurchase(clientSecret);  //complete the purchase with the client secret
        }
        else {  //free purchase has no client secret
            completePurchase();
        }
    }
    
    _orderFailed = (errorResponse, cancelPurchase) => {
        this.showConfirmAlert("Purchase Failed", errorResponse + ". Your card has not been charged. Return to the Store and try again.", 'red');
        cancelPurchase(errorResponse);
    }


    _chargeSuccess = () => {
        this.props.purchaseComplete(this.state.checkedOutAccount, true);  //purchase complete with the account, true = money was charged
    }

    _chargeFailure = (message) => {
        
        this.showConfirmAlert("Payment Failed", message + " You can try entering your payment information again, or return to the Store.", 'red'); 
        this.setState({cardChargeFailure: true});
    }
    
    
    _freeItemSucceed = () => {
        this.props.purchaseComplete(this.state.checkedOutAccount, false);  //purchase complete with the account, false = items were free
        this.forceUpdate();
    }
    
    _freeItemFailure = (message) => {      
        this.showConfirmAlert("Order Failed", message + " You can try again or return to the Store.", 'red'); 
    }


    _fetchPublishableKey = (callback) => {
        this.secureJSONFetch("/payments/stripe/pubkey", {}, 
                             (response) => callback(response), 
                             (error) => this.showConfirmAlert("Failed to Load Payment System", error, 'red')
                             );
    }


    render() {
        
        if (!this.props.visible)
            return null;
        
        let name;
        let email;
        if (this.props.account) {  //existing customer checkout
            name = this.props.account.name;
            email = this.props.account.email;
        }
        else {  //guest - use user entered fields
            name = this.state.name;
            email = this.state.email;
        } 
        
        const hideNameEmail = this.props.account || this.state.cardChargeFailure;
        
        return (
            <Fragment>

                {this.getConfirmAlertComponent()}

                <Button color="primary" style={{marginBottom: 20}} onClick={this._returnToStore}>
                    <ArrowBackIosIcon fontSize='small'/> 
                    <Typography variant="body1">Back to Store</Typography>   
                </Button>  

                {hideNameEmail ? 
                     <div style={{marginBottom: 15}}>
                            <Typography variant="body2">{"Billing Name: " + name}</Typography>                           
                     </div>
                    :
                    <Paper style={{marginBottom: 25}}>                           
                        <div style={{marginLeft: 10, marginRight: 10, paddingBottom: 20}}>

                            <Typography variant="body2" style={this.styles.paperLabel}>Customer Information</Typography>   
                            <div style={{margin: 25}}/>

                            <div style={{display: 'flex'}}>
                                <Tooltip title={this.props.checkout.isFree ? "Name (required)" : "Cardholder Name (required)"}>
                                    <TextField size="small" label={this.props.checkout.isFree ? "Name" : "Cardholder Name"} variant="outlined" 
                                               onChange={(event) => {this.setState({name: event.target.value});}}
                                               value={this.state.name} 
                                               fullWidth={true} 
                                               InputLabelProps={{ shrink: true}} />
                                </Tooltip>

                                <div style={{margin: 10}}/>

                                <Tooltip title="Email to associate with this purchase (required). You will receive your receipt by email.">
                                    <TextField size="small" label="Email" variant="outlined" 
                                               onChange={(event) => {this.setState({email: event.target.value});}}
                                               value={this.state.email} 
                                               fullWidth={true} 
                                               InputLabelProps={{ shrink: true}} />
                                </Tooltip>
                            </div>
                        </div>
                    </Paper>                    
                } 
                
                {this.props.checkout.isFree ? 
                    <div style={{display: 'flex', justifyContent: 'center'}}>
                        <Button disabled={!name} onClick={() => this._requestPurchase(this._freeItemSucceed, this._freeItemFailure)} 
                                variant="contained" color="primary" style={{color: 'white'}}>Request Free Products</Button>
                    </div>
                    :
                    <div>
                        <StripeManager name={name} email={email} 
                                        fetchPublishableKey={this._fetchPublishableKey}
                                        purchaseRequest={this._requestPurchase} 
                                        cardChargeSuccess={this._chargeSuccess}
                                        cardChargeFailure={this._chargeFailure}/>

                        <div style={{display: 'flex', justifyContent: 'center', marginTop: 100}}>

                            <a href="https://www.stripe.com" target="_blank" rel="noopener noreferrer">
                                {<img src={stripebadge} alt="stripe badge" width='400px'/>}
                            </a>

                        </div>
                    </div>                          
                }
                
            </Fragment>
        );
    }

}

export default withCookies(Checkout);
